
// jas made 



function validateName() {
    var name = document.getElementById('name').value;
    if (name.length == 0) {
        alert("Name can't be blank");
        return false;

    }
    if (!name.match(/^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/)) {
        alert("Please enter your correct name");//Validation Message
        return false;
    }
    return true;
}

function validatePhone() {
    var phone = document.getElementById('phone').value;
    if (phone.length == 0) {
        alert("Phone number can't be blank");//Validation Message
        return false;
    }

    if (!phone.match(/^[0]?[789]\d{9}$/)) {
        alert("Please enter a correct phone number");//Validation Message
        return false;
    }

    return true;

}

function validateEmail() {

    var email = document.getElementById('email').value;
    if (email.length == 0) {
        alert("Email can't be blank");//Validation Message
        return false;

    }

    if (!email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
        alert("Please enter a correct email address");//Validation Message
        return false;

    }

    return true;

}


function validateForm() {
    if (!validateName() || !validatePhone() || !validateEmail()) {

        alert("Form not submitted");//Validation Message
        return false;
    }
    else {
        submitted = true;
        return true;
    }
}



var popupContainer = document.getElementById("popup-container");
var closeBtn = document.getElementById("close-btn");

// Check if popup has already been shown
if (!sessionStorage.getItem("popupShown")) {
    // Show popup if it hasn't been shown
    window.addEventListener("load", function () {
        setTimeout(function () {
            popupContainer.style.display = "block";
            popupContainer.classList.add("show");
        }, 5000); // 5000 milliseconds = 5 seconds
    });

    // Add event listener to close button
    closeBtn.addEventListener("click", function () {
        popupContainer.classList.add("hide");
        setTimeout(function () {
            popupContainer.style.display = "none";
            popupContainer.classList.remove("hide", "show");
        }, 500); // Wait for animation to finish before removing popup from DOM

        // Set popupShown flag in session storage
        sessionStorage.setItem("popupShown", true);
    });
} else {
    // Hide popup if it has already been shown
    popupContainer.style.display = "none";
}

// Check if popup has already been shown
if (!sessionStorage.getItem("popupShown")) {
    console.log("Popup has not been shown yet.");

    // Your popup code goes here

    // Set popupShown flag in session storage
    sessionStorage.setItem("popupShown", true);
} else {
    console.log("Popup has already been shown in this session.");
}

